.contact-form {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.contact-form:first-child,
* + .contact-form {
  padding-top: var(--section-gap);
}

/* .layout__body > .goup .contact-form:first-child {
} */

.contact-form__container {
  display: flex;
  flex-direction: column;
}

.contact-form__sup-title {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.12em;
  color: rgba(var(--color-rgb), 0.5);
  text-transform: uppercase;
}

.contact-form__title {
  font-family: var(--font-family-title);
  font-size: 56px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color);
}
* + .contact-form__title {
  margin-top: 30px;
}
@media (--tablet) {
  .contact-form__title {
    font-size: 48px;
  }
  * + .contact-form__title {
    margin-top: 24px;
  }
}
@media (--mobile) {
  .contact-form__title {
    font-size: 38px;
  }
  * + .contact-form__title {
    margin-top: 20px;
  }
}

.contact-form__description {
  font-family: var(--font-family-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: rgba(var(--color-rgb), 0.8);
}
* + .contact-form__description {
  margin-top: 60px;
}
@media (--tablet) {
  * + .contact-form__description {
    margin-top: 48px;
  }
}
@media (--mobile) {
  * + .contact-form__description {
    margin-top: 24px;
  }
}

.contact-form__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
}

* + .contact-form__wrapper {
  margin-top: 32px;
}

.contact-form__left,
.contact-form__right {
  display: flex;
  flex-direction: column;
}

.contact-form__left {
  position: relative;

  justify-content: start;
}

.contact-form__right {
  position: relative;

  justify-content: baseline;
}

.contact-form__sub-title {
  display: flex;
  align-items: center;
  gap: 16px;

  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.12em;
  color: var(--color);
  text-transform: uppercase;
  white-space: nowrap;
  text-align: right;
}

.contact-form__sub-title::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;

  background-color: var(--color);
}

.contact-form__list {
  display: grid;
  grid-template-columns: repeat(2, auto);
  list-style: none;
  row-gap: 64px;
}

* + .contact-form__list {
  margin-top: 64px;
}

.contact-form__item:first-of-type {
  width: fit-content;
}

.contact-form__item {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: fit-content;
}

.contact-form__item__icon {
  --size: 24px;
  display: flex;
  align-items: center;

  width: var(--size);
  height: var(--size);
}

.contact-form__item__title {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.12em;
  color: rgba(var(--color-rgb), 0.5);
  text-transform: uppercase;
}

.contact-form__item__content {
  font-family: var(--font-family-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--color);
}

.contact-form__item__content a {
  color: var(--color);
  text-decoration: none;
}

@media (--mobile) {
  .contact-form__list {
    row-gap: 32px;
  }
}

@media (--tablet) {
  * + .contact-form__list {
    margin-top: 48px;
  }
}

@media (--tablet) {
  .contact-form__wrapper {
    gap: 48px;
  }
}

@media (--mobile) {
  .contact-form__wrapper {
    display: block;
  }
}

@media (--mobile) {
  * + .contact-form__right {
    margin-top: 48px;
  }
}

@media (--mobile) {
  .contact-form__left,
  .contact-form__right {
    justify-content: center;
  }
}

@media (--mobile) {
  .contact-form__list {
    grid-template-columns: repeat(1, auto);
    row-gap: 32px;
  }
}

@media (--mobile) {
  .contact-form__item {
    gap: 16px;
  }
}

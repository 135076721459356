* + .hero-video__play {
  margin-top: 26px;
}
@media (--mobile) {
  * + .hero-video__play {
    margin-top: 16px;
  }
}

.hero-video__play {
  position: relative;

  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;

  color: var(--white);

  transition:
    400ms opacity,
    400ms transform;

  will-change: opacity, transform;
}

@media (--mobile) {
  .hero-video__play {
    gap: 12px;
  }
}

.hero-video__play__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-video.hero-video--started.hero-video--playing .hero-video__play {
  transform: translateX(10%);

  opacity: 0;

  pointer-events: none;
}

.hero-video__play__img-container {
  width: 42px;
  height: 42px;
}

@media (--mobile) {
  .hero-video__play__img-container {
    width: 30px;
    height: 30px;
  }
}

.hero-video__play__img {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  font-weight: 500;
  line-height: 1.5;

  cursor: pointer;

  user-select: none;
  transition: ease-in-out 200ms;
}
.hero-video__play__img:hover {
  transform: scale(1.07);
}

.hero-video__play > .hero-video__play__img {
  display: flex;
  width: 42px;
  height: 42px;

  transition: 200ms transform;
}

.hero-video__play > .hero-video__play__img::before {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}

.hero-video__play > .hero-video__play__img + span {
  display: block;
  margin-left: 16px;

  font-size: var(--font-size-body);
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.1em;

  color: var(--white);
  text-transform: uppercase;
}

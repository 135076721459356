.hero-image {
  position: relative;

  height: auto;
  min-height: 720px;
  margin-top: var(--header-height);
  padding-top: 64px;

  background-color: black;
}
@media (--mobile) {
  .hero-image {
    display: flex;
    flex-direction: column-reverse;
    min-height: 400px;
  }
}

.hero-image--small-height {
  height: 480px;
}

@media (--mobile) {
  .hero-image--small-height {
    height: 418px;
  }
}

* + .hero-image {
  margin-top: 120px;
}
@media (--tablet) {
  * + .hero-image {
    margin-top: 80px;
  }
}
@media (--mobile) {
  * + .hero-image {
    margin-top: 50px;
  }
}

.hero-image__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1;
}

.hero-image__cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-image__wrapper > .max-width {
  height: 100%;
}

.hero-image__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@media (--mobile) {
  .hero-image__wrapper {
    position: static;
  }
  .hero-image.hero-image--started.hero-image--playing .hero-image__wrapper {
    pointer-events: auto;
  }
}

.hero-image__wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  padding-bottom: 94px;
}

.hero-image--small-height .hero-image__wrap {
  padding-bottom: 79px;
}
@media (--mobile) {
  .hero-image__wrap,
  .hero-image--small-height .hero-image__wrap {
    position: static;

    display: block;
    padding-bottom: 57px;
  }

  .hero-image--contact .hero-image__wrap {
    padding-bottom: 37px;
  }
}

.hero-image__content {
  user-select: none;
}

.hero-image--contact .hero-image__content {
  display: flex;

  align-items: center;

  justify-content: space-between;
}

@media (--tablet) {
  .hero-image--contact .hero-image__content {
    flex-direction: column;
    gap: 40px;
  }
}

@media (--mobile) {
  .hero-image--contact .hero-image__content {
    gap: 20px;
  }
}

.hero-image--contact .hero-image__content h1 {
  width: 100%;
  text-align: center;
}
@media (--tablet) {
  .hero-image--contact .hero-image__content h1 {
    text-align: left;
  }
}

.hero-image__content h1 {
  font-family: var(--font-family-title);
  font-size: var(--font-size-56);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
}

@media (--tablet) {
  .hero-image__content h1 {
    font-size: var(--font-size-40);
  }
}

@media (--mobile) {
  .hero-image__content h1 {
    font-size: 32px;
  }
}
.hero-image__content h1 > strong {
  color: var(--gold-500);
}

* + .hero-image__sub-title {
  margin-top: 60px;
}
@media (--mobile) {
  * + .hero-image__sub-title {
    margin-top: 24px;
  }
}

.hero-image__content > .hero-image__sub-title {
  font-family: var(--font-family-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.07em;
  color: var(--white);
  text-transform: uppercase;
}

@media (--tablet) {
  .hero-image__content > .hero-image__sub-title {
    font-size: 20px;
  }
}

@media (--mobile) {
  .hero-image__content > .hero-image__sub-title {
    font-size: var(--font-size-body);
  }
}

* + .hero-image__text {
  margin-top: 35px;
}

.hero-image__content .hero-image__sub-title > * + p {
  margin-top: 16px;

  @media (--tablet) {
    margin-top: 12px;
  }
}

.hero-image__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 2;

  content: '';

  display: block;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 77.84%);
}

.hero-image--soft-gradient .hero-image__gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 47.08%,
    rgba(0, 0, 0, 0.8) 90.1%
  );
}

.hero-image__text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 80px;
}
@media (--mobile) {
  .hero-image__text {
    display: block;
    gap: 0;
  }
}

.hero-image__text__left,
.hero-image__text__right {
  position: relative;

  max-width: 459px;
  padding-left: 16px;
}

.hero-image--only-text-left .hero-image__text__left,
.hero-image--only-text-left .hero-image__text__right {
  padding-left: 0;
}
.hero-image__text__sub-title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-style: normal;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.04em;
  color: var(--white);
}

.hero-image__text__sub-title::before {
  position: absolute;
  top: 0;
  left: 0;

  content: '';
  width: 1px;
  height: 100%;

  background: var(--white);
}
.hero-image--only-text-left .hero-image__text__sub-title::before {
  display: none;
}

* + .hero-image__text__cta {
  margin-top: 40px;
}

.hero-image__text__contact {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (--tablet) {
  .hero-image__text__contact {
    justify-content: flex-start;
  }
}

.hero-image__text__contact__item {
  display: flex;
  flex-direction: column;
  gap: 55px;
  width: fit-content;
  padding: 24px 32px;

  border-radius: 4px;
  background-color: var(--white);
}

@media (--tablet) {
  .hero-image__text__contact__item {
    gap: 40px;
    width: 340px;
    padding: 24px;
  }
}
@media (max-width: 360px) {
  .hero-image__text__contact__item {
    gap: 40px;
    width: 100%;
    padding: 12px;
  }
}

@media (--mobile) {
  .hero-image__text__contact__item {
    gap: 20px;
  }
}

.hero-image__text__contact__top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (--tablet) {
  .hero-image__text__contact__top {
    gap: 20px;
  }
}

.hero-image__text__contact__item__cta {
  display: flex;
  align-items: center;
  gap: 24px;
}

.hero-image__text__contact__item__title {
  font-family: var(--font-family-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: var(--black);
}

@media (--tablet) {
  .hero-image__text__contact__item__title {
    font-size: 18px;
  }
}

.hero-image__text__contact__item__sub-title {
  position: relative;

  width: 100%;
  padding-left: 10px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: rgba(var(--black-rgb), 0.5);
}

.hero-image__text__contact__item__sub-title::before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;

  background: rgba(var(--black-rgb), 0.5);
}

* + .hero-image__text__contact__item__sub-title {
  margin-top: 20px;
}

@media (--tablet) {
  * + .hero-image__text__contact__item__sub-title {
    margin-top: 16px;
  }
}

@media (--mobile) {
  * + .hero-image__text__contact__item__sub-title {
    margin-top: 12px;
  }
}

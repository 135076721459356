.numbers {
  --color: var(--black);

  overflow: hidden;
}

* + .numbers,
.numbers:first-child {
  margin-top: var(--section-gap);
}
@media (--mobile) {
  * + .numbers {
    margin-top: 48px;
  }
}

.group--theme-black .numbers {
  --color: var(--white);
}

.numbers__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
}
@media (--page-max-width) {
  .numbers__wrapper {
    flex-direction: column;
    gap: 48px;
  }
}

.numbers__wrapper--no-title {
  flex-direction: column;

  max-width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.numbers__left {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

@media (--mobile) {
  .numbers__wrapper--no-title .numbers__left {
    gap: 0;
  }
}

.numbers__wrapper--no-title .numbers__left {
  align-items: baseline;
  width: 100%;
}

.numbers__left__sup-title {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: var(--color);
  text-transform: uppercase;
  white-space: nowrap;
}

.numbers__wrapper--no-title .numbers__left__sup-title {
  color: rgba(var(--color-rgb), 0.6);
}
.numbers__left__sup-title::after {
  content: '';
  width: 100%;
  height: 1px;

  background-color: var(--color);
}

.numbers__wrapper--no-title .numbers__left__sup-title::after {
  display: none;
}

.numbers__left__title {
  display: flex;
  max-width: 10ch;

  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 400;
  line-height: 140%;
  color: var(--color);
}
@media (--page-max-width) {
  .numbers__left__title {
    max-width: 100%;
  }
}
@media (--mobile) {
  .numbers__left__title {
    font-size: 32px;
  }
}

.numbers__left--has-sup-title .numbers__left__title {
  max-width: 18ch;

  font-family: var(--font-family-text);
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  color: var(--color);
}

.numbers__right {
  display: flex;
  align-items: flex-end;
}
@media (--mobile) {
  .numbers__right {
    align-items: center;
  }
}

.numbers__list {
  display: flex;
  height: fit-content;
  list-style: none;
}
@media (--mobile) {
  .numbers__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 52px;
    row-gap: 32px;
  }
}

.numbers__item {
  --horizontal-padding: 40px;

  position: relative;

  display: flex;
  align-items: flex-start;
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
}

.numbers__wrapper--no-title .numbers__item {
  --horizontal-padding: 50px;
}

.numbers__item:first-of-type {
  padding-left: 0;
}
.numbers__item:last-of-type {
  padding-right: 0;
}
@media (--page-max-width) {
  .numbers__item {
    --horizontal-padding: 28px;
  }

  .numbers__item:first-of-type {
    align-items: baseline;
  }
}
@media (--mobile) {
  .numbers__item,
  .numbers__wrapper--no-title .numbers__item {
    --horizontal-padding: 0;

    align-items: center;
    width: 100%;
    padding-top: 24px;

    border-top: 1px solid var(--color);
  }
}

.numbers__item__wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: fit-content;
}
@media (--mobile) {
  .numbers__item__wrapper {
    gap: 16px;
  }
}

.numbers__item:not(:first-of-type)::before {
  position: absolute;
  left: 0;

  content: '';
  width: 1px;
  height: 100%;

  background-color: var(--color);
}
@media (--mobile) {
  .numbers__item::before {
    display: none;
  }
}

.numbers__item__number {
  font-family: var(--font-family-text);
  font-size: 37px;
  font-weight: 600;
  color: var(--color);
  white-space: nowrap;
}
@media (--page-max-width) {
  .numbers__item__number {
    font-size: 22px;
  }
}

.numbers__item__description {
  font-family: var(--font-family-text);
  font-size: 18px;
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.01em;
  color: var(--color);
}

@media (--mobile) {
  .numbers__item__description {
    font-size: var(--font-size-body);
  }
}

.press {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.group--theme-black .shows-list {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.press,
* + .press {
  margin-top: var(--section-gap);
}

.press__wrap {
  display: grid;
  grid-template-columns: auto 0.78fr;
  grid-gap: 56px;
}

@media (--tablet) {
  .press__wrap {
    grid-template-columns: 1fr;
  }
}

.press__articles {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

@media (--tablet) {
  .press__articles {
    gap: 56px;
  }
}

.press__article {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (--tablet) {
  .press__article {
    gap: 24px;
  }
}

@media (--mobile) {
  .press__article {
    gap: 20px;
  }
}

.press__article__title {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;

  font-family: var(--font-family-text);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: var(--color);
  text-transform: uppercase;
  white-space: nowrap;
}

@media (--tablet) {
  .press__article__title {
    font-size: 18px;
  }
}
@media (--mobile) {
  .press__article__title {
    font-size: var(--font-size-body);
  }
}
.press__article__title::after {
  content: '';
  width: 100%;
  height: 1px;

  background: rgba(var(--color-rgb), 0.5);
}

.press__article__content {
  max-width: 45ch;

  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-style: italic;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color);
}

@media (--tablet) {
  .press__article__content {
    font-size: var(--font-size-sub-title);
  }
}

@media (--mobile) {
  .press__article__content {
    max-width: 100%;
  }
}

.press__article__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (--mobile) {
  .press__article__bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.press__article__date {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: rgba(var(--color-rgb), 0.5);
}

.press__highlights {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
@media (--tablet) {
  .press__highlights {
    grid-row-start: 1;

    gap: 56px;
  }
}

.press__highlight {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (--mobile) {
  .press__highlight {
    gap: 24px;
  }
}

.press__highlight__sup-title {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: rgba(var(--color-rgb), 0.5);
  text-transform: uppercase;
  white-space: nowrap;
}
@media (--tablet) {
  .press__highlight__sup-title {
    font-size: var(--font-size-sub-title);
  }
}

.press__highlight__sup-title::after {
  content: '';
  width: 100%;
  height: 1px;

  background: rgba(var(--color-rgb), 0.5);
}

.press__highlight__image {
  overflow: hidden;
  width: 100%;
  height: 233px;

  border-radius: 4px;
  object-fit: cover;
}

@media (--tablet) {
  .press__highlight__image {
    height: 200px;
  }
}

@media (--mobile) {
  .press__highlight__image {
    height: 160px;
  }
}

.press__highlight__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.press__highlight__date {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: rgba(var(--color-rgb), 0.5);
}

.press__highlight__title {
  font-family: var(--font-family-text);
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color);
}

@media (--tablet) {
  .press__highlight__title {
    font-size: 18px;
  }
}

@media (--mobile) {
  .press__highlight__title {
    font-size: var(--font-size-body);
  }
}

.spectacle {
  --color: var(--black);
  --color-rgb: var(--black-rgb);

  margin-top: 88px;
}
@media (--mobile) {
  .spectacle {
    margin-top: 64px;
  }
}

.group--theme-black .spectacle {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.spectacle__wrapper {
  display: flex;
  flex-direction: column;
  gap: 88px;
}

@media (--tablet) {
  .spectacle__wrapper {
    gap: 120px;
  }
}

@media (--mobile) {
  .spectacle__wrapper {
    gap: 64px;
  }
}

.spectacle__sup-title {
  position: relative;

  display: flex;
  align-items: center;
  gap: 24px;

  font-family: var(--font-family-text);

  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
  white-space: nowrap;
}

.spectacle__sup-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;

  background-color: var(--color);
}

.spectacle__top {
  display: grid;
  align-items: center;
  grid-template-columns: 37% 1fr;
  gap: 70px;
}

@media (--tablet) {
  .spectacle__top {
    grid-template-columns: 1fr 1fr;
    gap: 64px;
  }
}

@media (--mobile) {
  .spectacle__top {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}

.spectacle__content {
  display: flex;
  flex-direction: column;
  gap: 54px;
}

@media (--mobile) {
  .spectacle__content {
    gap: 32px;
  }
}

.spectacle--reverse .spectacle__top {
  grid-template-columns: 1fr 37%;
}

@media (--tablet) {
  .spectacle--reverse .spectacle__top {
    grid-template-columns: 1fr 1fr;
  }
}

@media (--mobile) {
  .spectacle--reverse .spectacle__top {
    grid-template-columns: 1fr;
  }
}

.spectacle--reverse .spectacle__content {
  grid-column: 2 / 3;
}
@media (--mobile) {
  .spectacle--reverse .spectacle__content {
    grid-column: 1 / 2;
  }
}

.spectacle__content__top {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.spectacle__content__top__title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 400;
  line-height: 140%;
  color: var(--color);
}
@media (--mobile) {
  .spectacle__content__top__title {
    font-size: 32px;
  }
}
.spectacle__description {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: var(--color);
}

.spectacle__image {
  position: relative;

  display: flex;
  justify-content: center;
  overflow: hidden;
  width: auto;
  height: 496px;

  border-radius: 4px;
}

@media (--mobile) {
  .spectacle__image {
    height: 320px;
  }
}

.spectacle--reverse .spectacle__image {
  order: -1;
}
@media (--mobile) {
  .spectacle--reverse .spectacle__image {
    order: 1;
  }
}

.spectacle__image img {
  width: 100%;
  height: auto;

  object-fit: cover;
  user-select: none;
}

.spectacle__bottom {
  display: grid;
  grid-template-columns: 32% 1fr;

  gap: 118px;
}

@media (--mobile) {
  .spectacle__bottom {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

.spectacle__bottom__container {
  display: flex;
  flex-direction: column;
  gap: 38px;
}

@media (--mobile) {
  .spectacle__bottom__container {
    gap: 32px;
  }
}

.spectacle__bottom__sup-title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}

.spectacle__bottom__title {
  font-family: var(--font-family-text);
  font-size: 24px;
  font-weight: 600;
  line-height: 170%;
  color: var(--color);
}

.spectacle__bottom__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 62px;
}

@media (--mobile) {
  .spectacle__bottom__wrapper {
    grid-template-columns: 1fr;
    gap: 34px;
  }
}

.spectacle__bottom__item {
  display: flex;
  flex-direction: column;
  gap: 38px;
}

@media (--mobile) {
  .spectacle__bottom__item {
    gap: 32px;
  }
}

.spectacle__bottom__item__icon {
  --size: 32px;
  display: block;
  width: var(--size);
  height: var(--size);
}

.spectacle__bottom__item__text {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.01em;

  color: var(--color);
}

.spectacle__bottom__item__text li,
.spectacle__description li {
  display: flex;
  list-style-type: none;
}
.spectacle__bottom__item__text li {
  margin-left: 32px;
}
@media (--mobile) {
  .spectacle__bottom__item__text li {
    margin-left: 24px;
  }
}

.spectacle__description li {
  align-items: center;
}

.spectacle__bottom__item__text p + ul {
  margin-top: 18px;
}
@media (--mobile) {
  .spectacle__bottom__item__text p + ul {
    margin-top: 16px;
  }
}

.spectacle__description p + ul {
  margin-top: 34px;
}
@media (--mobile) {
  .spectacle__description p + ul {
    margin-top: 32px;
  }
}

.spectacle__bottom__item__text li + li {
  margin-top: 14px;
}
@media (--mobile) {
  .spectacle__bottom__item__text li + li {
    margin-top: 12px;
  }
}

.spectacle__bottom__item__text li::before,
.spectacle__description li::before {
  content: '';
  flex-shrink: 0;

  width: 10px;
  height: 10px;
  margin-right: 18px;

  background-image: url('/static/icons/ellipse-black.svg');
  background-size: contain;
}

@media (--mobile) {
  .spectacle__bottom__item__text li::before,
  .spectacle__description li::before {
    margin-right: 12px;
  }
}
.spectacle__bottom__item__text li::before {
  margin-top: 8.4px;
}

.group--theme-black .spectacle__bottom__item__text li::before,
.spectacle__description li::before {
  background-image: url('/static/icons/ellipse-white.svg');
}

.heading {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.group--theme-black .heading {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

/* ,
:--theme-dark .heading {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--theme-light .heading {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}
 */
* + .heading,
.heading:first-child {
  margin-top: var(--section-gap);
}

.heading__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 24px;
  row-gap: 40px;
}

.heading--has-cta .heading__wrapper {
  align-items: center;
}

@media (--tablet) {
  .heading--has-cta .heading__wrapper {
    align-items: flex-start;
    row-gap: 30px;
  }
}

.heading--vertical .heading__wrapper {
  flex-direction: column;
  align-items: flex-start;
}

@media (--tablet) {
  .heading__wrapper {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 40px;
  }
}

.heanding__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 50%;
  text-align: left;
}
.heading--vertical .heanding__left {
  max-width: 100%;

  text-align: center;
}

.heading--vertical:not(.heading--has-cta) .heanding__left {
  align-items: center;
  justify-content: center;
}
@media (--tablet) {
  .heanding__left {
    max-width: 100%;
  }
}

.heading__sup-title {
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.8);
  text-transform: uppercase;
}
@media (--tablet) {
  .heading__sup-title {
    font-size: var(--font-size-body);
  }
}
@media (--mobile) {
  .heading__sup-title {
    font-size: 15px;
  }
}

.heading__title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--color);
}

.heading__title--h4 {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.heading--has-cta .heading__title {
  text-align: left;
}

* + .heading__title {
  margin-top: 38px;
}
@media (--tablet) {
  .heading__title {
    font-size: 28px;
  }

  .heading__title--h4 {
    font-size: 18px;
  }
}
@media (--mobile) {
  .heading__title:not(.heading__title--h2) {
    font-size: 21px;
    line-height: 140%;
  }

  * + .heading__title {
    margin-top: 18px;
  }
}

@media (--mobile) {
  .heading__title br {
    display: none;
  }
}

.heading__right {
  width: 100%;
  max-width: 50%;

  font-size: var(--font-size-body);
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(var(--color-rgb), 0.7);
}

@media (--tablet) {
  .heading__right {
    max-width: 100%;
  }
}

.heading__right.heading__right--max-width {
  justify-content: flex-start;
  max-width: 37ch;
}

.heading__right * + p {
  margin-top: 16px;
}

.heading__right a {
  color: inherit;
  text-decoration-color: inherit;
  text-underline-offset: 0.13em;
  transition:
    200ms color,
    200ms text-decoration-color;
}
.heading__right a:hover {
  color: var(--color);
}

.heading__cta--vertical {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 36px;
}

@media (--tablet) {
  .heading__cta--vertical {
    flex-direction: column;
    gap: 24px;
  }
}

.heading__cta__item {
  position: relative;

  display: flex;
}

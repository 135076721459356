.banner {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .banner,
.banner:first-child {
  margin-top: var(--section-gap);
}

.banner__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
}

.banner--horizontal .banner__wrap {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: auto 40% auto;
  grid-gap: 72px;
}

@media (--tablet) {
  .banner--horizontal .banner__wrap {
    grid-template-columns: auto 1fr auto;
  }
}
@media (--mobile) {
  .banner--horizontal .banner__wrap {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}
.banner__sup-title {
  width: fit-content;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}

.banner__middle {
  display: flex;
  flex-direction: column;
}

.banner__title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: var(--color);
  text-align: center;
}

.banner--horizontal .banner__title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-40);
  font-weight: 600;
  line-height: 140%;
  color: var(--color);
  text-align: left;
}

.banner__description {
  max-width: 80ch;
  margin-right: auto;
  margin-left: auto;

  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.1em;
  color: var(--color);
  text-align: center;
}

@media (--mobile) {
  .banner__description {
    max-width: 100%;
  }
}

.banner--horizontal .banner__description {
  max-width: 100%;

  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.1em;
  color: var(--color);
  text-align: left;
}
.banner__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: fit-content;
}

@media (--mobile) {
  .banner__right {
    align-items: flex-start;
  }
}
.banner__sub-title {
  font-family: var(--font-family-text);

  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
}

* + .banner__description {
  margin-top: 44px;
}

* + .banner--horizontal .banner__description {
  margin-top: 56px;
}

.banner__cta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner--horizontal .banner__cta {
  justify-content: flex-start;
}

* + .banner__cta {
  margin-top: 64px;
}

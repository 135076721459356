* + .cultural-site-slider {
  margin-top: 20px;
}

.cultural-site-slider {
  position: relative;
}

.cultural-site-slider::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;

  content: '';
  width: 20px;

  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 38%,
    rgba(0, 0, 0, 0) 100%
  ); */
}

.cultural-site-slider__container {
  --slide-width: 258px;
  --slide-height: 108px;
  --left-offset: 0px;

  width: calc(
    100% + var(--left-offset) + (100vw - (var(--page-max-width))) / 2
  ) !important;

  margin-left: calc(-1 * var(--left-offset));
  padding-left: var(--left-offset);
}

@media (max-width: 1120px) {
  .cultural-site-slider__container {
    width: calc(
      100% + var(--left-offset) + var(--page-right-padding)
    ) !important;
  }
}

@media (--tablet) {
  .cultural-site-slider__container {
    --slide-width: calc(
      75vw - var(--page-right-padding) - var(--page-left-padding)
    );
    --left-offset: 0;

    overflow: visible !important;
    max-width: calc(var(--slide-width) * var(--nb-slide));
  }
}

.cultural-site-slider__item {
  position: relative;

  max-width: var(--slide-width);
  min-width: var(--slide-width);
  height: var(--slide-height);

  font-size: 50px;
  font-weight: 500;
  color: #fff;

  border-radius: 4px;

  background: grey;
}

.cultural-site-slider__item__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cultural-site-slider__item__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(22, 22, 22, 0.8) 100%
    ),
    linear-gradient(0deg, rgba(37, 37, 37, 0.6) 0%, rgba(37, 37, 37, 0.6) 100%);
}

.cultural-site-slider__item__title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 32px;
}

.cultural-site-slider__item__title a {
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: white;
  text-decoration: none;
}

.cultural-site-slider__item__title a svg {
  margin-top: 5px;
}

.cultural-site-slider__item__title a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.highlights {
  --offset-top: 80px;
  position: relative;

  overflow: hidden;
  margin-top: calc(-1 * var(--offset-top));
}

.highlights__body {
  --left-content-width: 240px;
  --gap: 60px;

  display: flex;
  gap: var(--gap);
  margin-top: var(--offset-top);
}

@media (--tablet) {
  * + .highlights {
    margin-top: 56px;
  }

  .highlights {
    --offset-top: 0;
  }

  .highlights__body {
    display: block;
  }
}

.highlights__left {
  width: var(--left-content-width);
  margin-top: 50px;
}

@media (--tablet) {
  .highlights__left {
    width: 100%;
    margin-top: 0;
  }
}

.highlights__left__title h2 {
  display: table-caption;

  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 400;
  line-height: 150%;
  color: var(--black);
  text-align: right;
}

@media (--tablet) {
  .highlights__left__title h2 {
    display: block;
    text-align: left;
  }
}

.highlights__slider {
  --max-width: calc(var(--page-max-width) + var(--page-right-padding));
  --card-width: calc((var(--max-width) / 3));

  display: flex;
  overflow: visible !important;

  width: 100%;
  max-width: calc(var(--max-width) - var(--gap) - var(--left-content-width));
}

@media (--tablet) {
  * + .highlights__slider {
    margin-top: 40px;
  }

  .highlights__slider {
    --card-width: calc(
      75vw - var(--page-right-padding) - var(--page-left-padding)
    );

    max-width: calc(var(--card-width) * var(--nb-slide));
  }
}

.highlights__slide {
  background-color: white;
}

.highlights__item {
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: visible !important;

  max-width: var(--card-width);
  min-width: var(--card-width);
  margin-top: calc(-1 * var(--offset-top));
}

.highlights__item:not(:last-of-type)::after {
  position: absolute;
  top: var(--offset-top);
  right: -40px;
  bottom: 0;
  left: 100%;

  content: '';

  background-color: white;
}

.highlights__item .highlights__image {
  position: relative;

  overflow: hidden;

  width: 100%;
  height: 247px;
}

.highlights__item--text .highlights__image {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;
}

.highlights__item .highlights__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.highlights__content {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.highlights__item--text .highlights__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1;

  padding: 40px;
}

.highlights__item--text .highlights__content .button__text {
  font-size: 13px;
  text-align: left;
}

.highlights__content__cta .button {
  position: inherit;
}

.highlights__content_title a {
  color: black;
  text-decoration: none;
}

.highlights__content_title a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  content: '';
}

.highlights__item--text * + .highlights__content__cta {
  margin-top: 16px;
}

* .highlights__content {
  margin-top: 24px;
}

.highlights__item--text .highlights__content_title {
  font-family: var(--font-family-title);
  font-size: 50px;
}

.highlights__item:not(.highlights__item--text) * + .highlights__content__cta {
  margin-top: 16px;
}

.highlights__content .highlights__left__title {
  font-family: var(--font-family-text);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.04em;
  color: var(--black);
}

.highlights__content .highlights__content__subtitle {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: rgba(var(--black-rgb), 0.5);
}
.highlights__item--text .highlights__content__subtitle {
  font-size: 20px;
}
* + .highlights__content__subtitle {
  margin-top: 12px;
}

.highlights__content .highlights__content__text {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.04em;

  color: var(--black);
}

.highlights__item--theme-white .highlights__content,
.highlights__item--theme-white .highlights__content__subtitle,
.highlights__item--theme-white .highlights__content__text,
.highlights__item--theme-white .highlights__content_title a {
  color: white;
}

* + .highlights__content__text {
  margin-top: 24px;
}

.highlights__content .highlights__content__text b {
  font-weight: 700;
}

.highlights__item .button__text::after {
  display: none;
}

.contact-form * + form {
  margin-top: 32px;
}
@media (--mobile) {
  .contact-form * + form {
    margin-top: 24px;
  }
}

.contact-form__form {
  position: relative;

  overflow: hidden;
}
/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.contact-form__form--state-success form {
  animation: fadeOut 200ms forwards;
  pointer-events: none;
}

.contact-form__form__split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.contact-form__form__input-container,
.contact-form__form__input-container-textarea {
  --border-radius: 4px;

  position: relative;

  display: flex;
  width: 100%;
}

@media (--tablet) {
  .contact-form__form__split {
    display: flex;
    flex-direction: column;
  }
}
@media (--mobile) {
  .contact-form__form__split {
    gap: 8px;
  }
}

.contact-form__form__split + .contact-form__form__split {
  margin-top: 8px;
}
@media (--mobile) {
  .contact-form__form__split + .contact-form__form__split {
    margin-top: 8px;
  }
}

.contact-form__form__split--flex {
  display: flex;
}

.contact-form__form__input,
.contact-form__form__textarea {
  width: 100%;
  padding: 16px;

  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.01em;

  color: var(--black);

  border: 1px solid rgba(var(--black-rgb), 0.1);

  border-radius: var(--border-radius);
  background: var(--white);

  transition: border-color 200ms;
}
.contact-form__form__input:focus,
.contact-form__form__textarea:focus {
  outline: none;
  border-color: rgb(var(--black-rgb), 1);
}
* + .contact-form__form__input,
* + .contact-form__form__textarea {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.contact-form__form__input::placeholder,
.contact-form__form__textarea::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: rgba(var(--black-rgb), 0.7);
}

form > * + .contact-form__form__input-container,
* + .contact-form__form__input-container-textarea {
  margin-top: 8px;
}

@media (--mobile) {
  form > * + .contact-form__form__input-container,
  * + .contact-form__form__input-container-textarea {
    margin-top: 6px;
  }
}

.contact-form__form__textarea {
  min-height: 200px;
  resize: vertical;
}

.contact-form__form__error {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-weight: 300;
  line-height: 100%;
  color: var(--red);
}

* + .contact-form__form__error {
  margin-top: 24px;
}

* + .contact-form__form--state-error .contact-form__form__cta {
  margin-top: 11px;
}

* + .contact-form__form__cta {
  margin-top: 24px;
}
@media (--mobile) {
  * + .contact-form__form__cta {
    margin-top: 21px;
  }
}

* + .contact-form__form__success {
  margin-top: 24px;
}

.contact-form__form__success__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
    scale: 0.9;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    scale: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-7deg);
  }
}

* + .contact-form__form {
  margin-top: 24px;
}

.contact-form__form__success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  gap: 26px;

  animation: fadeIn 200ms forwards;
}

@media (--mobile) {
  .contact-form__form__success {
    gap: 16px;
  }
}

.contact-form__form__success__top {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contact-form__form__success__sup-title {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: rgba(var(--black-rgb), 0.5);
  text-transform: uppercase;
}

.contact-form__form__success__title {
  font-family: var(--font-family-text);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: var(--black);
}

.contact-form__form__success__description {
  font-family: var(--font-family-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: rgba(var(--black-rgb), 0.8);
}

.links-list {
  --gap: 100px;
  position: sticky;
  top: calc(var(--header-height));
  z-index: calc(var(--z-index-header) - 1);

  overflow: hidden;

  background-color: var(--white);
}

@media (--mobile) {
  .links-list {
    display: none;
  }
}

* + .links-list {
  margin-top: 88px;
}

.links-list__wrapper {
  display: flex;
  align-items: center;
  width: fit-content;

  height: 100%;

  height: 59px;
  margin: 0 auto;

  border-bottom: 2px solid rgba(var(--black-rgb), 0.1);
}
@media (--tablet) {
  .links-list__wrapper {
    --gap: 40px;
    height: 44px;
  }
}

/* .links-list__list {
  display: flex;
  width: 100%;
  height: 100%;
} */

.links-list__item {
  --offset: -32px;

  position: relative;

  display: flex;
  align-items: center;
  height: 100%;

  text-decoration: none;
  text-transform: uppercase;
  pointer-events: auto;
  perspective: 800px;
  perspective-origin: 50% 0;
}

.links-list__item__main {
  --padding-left: calc(var(--gap) / 2);
  --padding-right: calc(var(--gap) / 2);

  position: relative;

  display: flex;
  align-items: center;
  height: 100%;
  margin-right: var(--padding-right);
  margin-left: var(--padding-left);

  font-size: 17px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: rgba(var(--black-rgb), 0.8);
  text-decoration: none;
  text-transform: uppercase;
  cursor: default;

  transition: text-decoration-color 200ms;
}

@media (--tablet) {
  .links-list__item__main {
    font-size: var(--font-size-body);
  }
}

.links-list__item__main:hover {
  color: rgba(var(--black-rgb), 1);
}

.links-list__item__main--current {
  width: fit-content;

  font-weight: 600;

  border-bottom: 2px solid rgba(var(--black-rgb), 1);
}
a.links-list__item__main {
  cursor: pointer;
}
.links-list__item:last-of-type .links-list__item__main {
  margin-right: 0;
}
.links-list__item:first-of-type .links-list__item__main {
  margin-left: 0;
}
* + .links-list__item__main__title {
  margin-left: 10px;
}

.expertise {
  --color: var(--black);
  --color-rgb: var(--black-rgb);

  margin-top: 88px;
}
@media (--mobile) {
  .expertise {
    margin-top: 64px;
  }
}

.group--theme-black .expertise {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.expertise__wrapper {
  display: flex;
  flex-direction: column;
  gap: 88px;
}

@media (--tablet) {
  .expertise__wrapper {
    gap: 120px;
  }
}

@media (--mobile) {
  .expertise__wrapper {
    gap: 64px;
  }
}

.expertise__top {
  display: grid;
  align-items: center;
  grid-template-columns: 30% 1fr;
  gap: 120px;
}

@media (--tablet) {
  .expertise__top {
    grid-template-columns: 1fr 1fr;
    gap: 64px;
  }
}

@media (--mobile) {
  .expertise__top {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}

.expertise__content {
  display: flex;
  flex-direction: column;
  gap: 54px;
}

@media (--mobile) {
  .expertise__content {
    gap: 32px;
  }
}

.expertise--reverse .expertise__top {
  grid-template-columns: 1fr 30%;
}

@media (--tablet) {
  .expertise--reverse .expertise__top {
    grid-template-columns: 1fr 1fr;
  }
}

@media (--mobile) {
  .expertise--reverse .expertise__top {
    grid-template-columns: 1fr;
  }
}

.expertise--reverse .expertise__content {
  grid-column: 2 / 3;
}
@media (--mobile) {
  .expertise--reverse .expertise__content {
    grid-column: 1 / 2;
  }
}

.expertise__content__top {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.expertise__content__top__sup-title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}

.expertise__content__top__title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 400;
  line-height: 140%;
  color: var(--color);
}
@media (--mobile) {
  .expertise__content__top__title {
    font-size: 32px;
  }
}
.expertise__description {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: var(--color);
}

.expertise__description p + p {
  margin-top: 24px;
}

.expertise__image {
  position: relative;

  display: flex;
  justify-content: center;
  overflow: hidden;
  width: auto;
  height: 496px;

  border-radius: 4px;
}

@media (--mobile) {
  .expertise__image {
    height: 320px;
  }
}

.expertise__image__legend {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;

  padding-bottom: 32px;
  padding-left: 40px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 600;
  line-height: 170%;
  letter-spacing: 0.1em;

  color: var(--white);
}

@media (--mobile) {
  .expertise__image__legend {
    gap: 16px;
    padding-bottom: 24px;
    padding-left: 24px;
  }
}
.expertise__image__legend::before {
  --size: 10px;

  content: '';
  display: block;
  width: var(--size);
  height: var(--size);

  background-image: url('/static/icons/ellipse-white.svg');
  background-size: contain;
}

.expertise--reverse .expertise__image {
  order: -1;
}
@media (--mobile) {
  .expertise--reverse .expertise__image {
    order: 1;
  }
}

.expertise__image img {
  width: 100%;
  height: auto;

  object-fit: cover;
  user-select: none;
}

.expertise__bottom {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
@media (--mobile) {
  .expertise__bottom {
    gap: 32px;
  }
}

.expertise__bottom__title {
  position: relative;

  display: flex;
  align-items: center;
  gap: 24px;

  width: 100%;
}
@media (--mobile) {
  .expertise__bottom__title {
    gap: 16px;
  }
}

.expertise__bottom__title::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;

  background-color: var(--color);
}

.expertise__bottom__title__title {
  width: fit-content;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: var(--color);
  text-transform: uppercase;
  white-space: nowrap;
}

.expertise__bottom__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
  max-width: 896px;
  margin-left: auto;
}
@media (--tablet) {
  .expertise__bottom__wrapper {
    max-width: 100%;
  }
}
@media (--mobile) {
  .expertise__bottom__wrapper {
    grid-template-columns: 1fr;
    gap: 34px;
    margin-left: 0;
  }
}

.expertise__bottom__item {
  display: flex;
  flex-direction: column;

  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0.01em;

  color: var(--color);
}

.expertise__bottom__item li,
.expertise__description li {
  display: flex;
  list-style-type: none;
}
.expertise__bottom__item li {
  margin-left: 32px;
}
@media (--mobile) {
  .expertise__bottom__item li {
    margin-left: 24px;
  }
}

.expertise__description li {
  align-items: center;
}

.expertise__bottom__item p + ul {
  margin-top: 18px;
}
@media (--mobile) {
  .expertise__bottom__item p + ul {
    margin-top: 16px;
  }
}

.expertise__description p + ul {
  margin-top: 34px;
}
@media (--mobile) {
  .expertise__description p + ul {
    margin-top: 32px;
  }
}

.expertise__bottom__item li + li {
  margin-top: 14px;
}
@media (--mobile) {
  .expertise__bottom__item li + li {
    margin-top: 12px;
  }
}

.expertise__bottom__item li::before,
.expertise__description li::before {
  content: '';
  flex-shrink: 0;

  width: 10px;
  height: 10px;
  margin-right: 18px;

  background-image: url('/static/icons/ellipse-black.svg');
  background-size: contain;
}

@media (--mobile) {
  .expertise__bottom__item li::before,
  .expertise__description li::before {
    margin-right: 12px;
  }
}
.expertise__bottom__item li::before {
  margin-top: 8.4px;
}

.group--theme-black .expertise__bottom__item li::before,
.expertise__description li::before {
  background-image: url('/static/icons/ellipse-white.svg');
}

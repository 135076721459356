.hero-video {
  position: relative;

  width: 100%;
  margin-top: var(--header-height);

  background-color: black;
}

@media (--mobile) {
  .hero-video {
    display: flex;
    flex-direction: column-reverse;
  }
}

* + .hero-video {
  margin-top: 120px;
}
@media (--tablet) {
  * + .hero-video {
    margin-top: 80px;
  }
}
@media (--mobile) {
  * + .hero-video {
    margin-top: 50px;
  }
}

.hero-video__wrapper > .max-width {
  height: 100%;
}

.hero-video__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.hero-video__wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.hero-video--layout-realisation .hero-video__wrap {
  justify-content: flex-end;
  padding-bottom: 123px;
}

@media (--mobile) {
  .hero-video--layout-realisation .hero-video__wrap {
    justify-content: flex-start;
    margin-top: 15%;
    padding-bottom: 0;
  }
}

.hero-video__content {
  user-select: none;
  transition:
    400ms opacity,
    400ms transform;

  will-change: transform;
}
.hero-video.hero-video--started.hero-video--playing .hero-video__content {
  transform: translateX(-10%);
  opacity: 0;

  pointer-events: none;
}

.hero-video__content h1 {
  font-family: var(--font-family-title);
  font-size: var(--font-size-56);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
}

@media (--tablet) {
  .hero-video__content h1 {
    font-size: var(--font-size-40);
  }
}

@media (--mobile) {
  .hero-video__content h1 {
    font-size: 32px;
  }
}
.hero-video__content h1 > strong {
  color: var(--gold-500);
}

.hero-video__content > .hero-video__sub-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: var(--white);
}

@media (--tablet) {
  .hero-video__content > .hero-video__sub-title {
    font-size: 20px;
  }
}

@media (--mobile) {
  .hero-video__content > .hero-video__sub-title {
    font-size: var(--font-size-body);
  }
}

* + .hero-video__sub-title {
  margin-top: 26px;
}

@media (--mobile) {
  * + .hero-video__sub-title {
    margin-top: 16px;
  }
}

.hero-video__content .hero-video__sub-title > * + p {
  margin-top: 16px;

  @media (--tablet) {
    margin-top: 12px;
  }
}

.hero-video__player {
  position: relative;

  width: 100%;
  height: 0;

  padding-bottom: 550px;
}
@media (--page-max-width) {
  .hero-video__player {
    padding-bottom: 42%;
  }
}
@media (--mobile) {
  .hero-video__player {
    padding-bottom: 300px;
  }
}
.hero-video__player__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-video__player__cover > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: var(--padding-bottom);
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
}

.hero-video__player__cover::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  display: block;

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 53.93%,
      #fff 86.84%
    ),
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 25.64%,
      rgba(255, 255, 255, 0) 70.82%
    );
}

.hero-video__player__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  opacity: 0;
}
.hero-video.hero-video--ready .hero-video__player__wrap {
  opacity: 1;

  transition: 200ms opacity;
}
@media (--mobile) {
  .hero-video__player__wrap {
    display: none;
  }
}

.hero-video__player__wrap::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  content: '';

  height: 300px;

  transition: 500ms opacity;
  pointer-events: none;
}

.hero-video__player__wrap > * {
  position: relative;

  top: -50%;
}

.hero-video__player__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  display: block;

  background-image: linear-gradient(
    180deg,
    rgba(12, 19, 40, 0.7) 0%,
    rgba(12, 19, 40, 0.455) 100%
  );

  transition: 500ms opacity;
  pointer-events: none;
}
.hero-video.hero-video--started.hero-video--playing
  .hero-video__player__gradient {
  opacity: 0;

  transition: 2000ms opacity;
}

.shows-list {
  --color: var(--black);
  --color-rgb: var(--black-rgb);

  margin-top: var(--section-gap);
}

.group--theme-black .shows-list {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.shows-list__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 56px;
  list-style: none;
  row-gap: 88px;
}

@media (--mobile) {
  .shows-list__list {
    grid-template-columns: 1fr;
    row-gap: 56px;
  }
}

.shows-list__item {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  text-align: left;
}
@media (--mobile) {
  .shows-list__item {
    gap: 20px;
  }
}

.shows-list__item__sup-title-line {
  position: relative;

  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.1em;

  color: var(--color);
  text-transform: uppercase;
  white-space: nowrap;
}
@media (--mobile) {
  .shows-list__item__sup-title-line {
    gap: 16px;
  }
}

.shows-list__item__sup-title-line::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;

  background-color: var(--color);
}

.shows-list__item__container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
}
@media (--mobile) {
  .shows-list__item__container {
    flex-direction: column-reverse;
    gap: 22px;
  }
}

.shows-list__item__sup-title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}

.shows-list__item__image {
  position: relative;

  overflow: hidden;
  width: auto;
  height: 361px;

  border-radius: 4px;
}
@media (--tablet) {
  .shows-list__item__image {
    width: 100%;
    height: 279px;
  }
}

.shows-list__item__image::before {
  position: absolute;
  top: 0;
  left: 0;

  content: '';
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 80.38%,
    rgba(13, 13, 13, 0.9) 100%
  );
}
.shows-list__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shows-list__item__legend {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;

  padding-bottom: 22px;
  padding-left: 30px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.01em;

  color: var(--white);
}

@media (--mobile) {
  .shows-list__item__legend {
    gap: 16px;
    padding-bottom: 16px;
    padding-left: 20px;
  }
}

.shows-list__item__legend::before {
  --size: 10px;

  content: '';
  display: block;
  width: var(--size);
  height: var(--size);

  background-image: url('/static/icons/ellipse-white.svg');
  background-size: contain;
}

.shows-list__item__title {
  font-family: var(--font-family-text);
  font-size: 26px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--color);
}
.shows-list__item__title a {
  color: var(--color);
  text-decoration: none;
}
.shows-list__item__title a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
@media (--mobile) {
  .shows-list__item__title {
    font-size: 20px;
  }
}

* + .shows-list__item__spectators {
  margin-top: 24px;
}

@media (--mobile) {
  * + .shows-list__item__spectators {
    margin-top: 16px;
  }
}

.shows-list__item__spectators {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
}

@media (--mobile) {
  .shows-list__item__spectators {
    gap: 8px;
  }
}

.shows-list__item__spectators::before {
  --size: 23px;

  content: '';
  display: block;
  width: var(--size);
  height: var(--size);

  background-image: url('/static/icons/person.svg');
  background-size: contain;
}

* + .shows-list__item__cta {
  margin-top: 30px;
}

@media (--mobile) {
  * + .shows-list__item__cta {
    margin-top: 20px;
  }
}

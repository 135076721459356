.text-and-cards {
  --color: var(--white);
  position: relative;

  overflow: hidden;
}

* + .text-and-cards,
.text-and-cards:first-child {
  margin-top: var(--section-gap);
}

.text-and-cards__body {
  display: grid;

  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
}

@media (--tablet) {
  .text-and-cards__body {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

.text-and-cards__text h2 {
  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: var(--color);
}

@media (--tablet) {
  .text-and-cards__text h2 {
    font-size: 32px;
  }
}

@media (--mobile) {
  .text-and-cards__text h2 {
    font-size: 24px;
  }
}

.text-and-cards__text h2 + .text-and-cards__content {
  margin-top: 60px;
}

@media (--tablet) {
  .text-and-cards__text h2 + .text-and-cards__content {
    margin-top: 40px;
  }
}

@media (--mobile) {
  .text-and-cards__text h2 + .text-and-cards__content {
    margin-top: 32px;
  }
}

.text-and-cards__text .text-and-cards__content p {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: var(--color);
}

.text-and-cards__text .text-and-cards__content p + p {
  margin-top: 20px;
}

@media (--tablet) {
  .text-and-cards__text .text-and-cards__content p + p {
    margin-top: 16px;
  }
}

.text-and-cards__text .text-and-cards__content + .text-and-cards__cta {
  margin-top: 64px;
}

@media (--tablet) {
  .text-and-cards__text .text-and-cards__content + .text-and-cards__cta {
    margin-top: 48px;
  }
}

@media (--mobile) {
  .text-and-cards__text .text-and-cards__content + .text-and-cards__cta {
    margin-top: 32px;
  }
}

/** Cards **/

.text-and-cards__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

@media (--mobile) {
  .text-and-cards__cards {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.text-and-cards__card {
  position: relative;

  display: flex;

  align-items: center;
  gap: 20px;
  padding: 24px;

  border: 1px solid var(--black);
  border-radius: 10px;
  background: linear-gradient(
      286deg,
      rgba(255, 255, 255, 0) 47.09%,
      rgba(255, 255, 255, 0.08) 86.39%
    ),
    #1e1e1e;
}

@media (--tablet) {
  .text-and-cards__card {
    padding: 20px;
  }
}

@media (--mobile) {
  .text-and-cards__card {
    padding: 16px;
  }
}

.text-and-cards__card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: 10px;
  background: linear-gradient(
      225deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.15)
    )
    border-box;
  pointer-events: none;
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.text-and-cards__card__icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  padding: 7px;

  border-radius: 5px;

  background: rgba(var(--white-rgb), 0.1);
}

@media (--tablet) {
  .text-and-cards__card__icon {
    width: 32px;
    height: 32px;
  }
}

.text-and-cards__card__icon svg {
  width: 100%;
  height: 100%;
}

.text-and-cards__card__content .text-and-cards__card__title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: var(--white);
}

@media (--tablet) {
  .text-and-cards__card__content .text-and-cards__card__title {
    font-size: var(--font-size-sub-title);
  }
}

.text-and-cards__card__content .text-and-cards__card__description {
  font-family: var(--font-family-text);
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  color: var(--white);
}

.text-and-cards__card__content
  .text-and-cards__card__title
  + .text-and-cards__card__description {
  margin-top: 10px;
}

@media (--tablet) {
  .text-and-cards__card__content
    .text-and-cards__card__title
    + .text-and-cards__card__description {
    margin-top: 8px;
  }
}

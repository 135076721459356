* + .references {
  margin-top: var(--section-gap);
}
.references {
  position: relative;

  overflow: hidden;
}
.references__body {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px;
}

@media (--tablet) {
  .references__body {
    gap: 40px;
  }
}

@media (--mobile) {
  .references__body {
    grid-template-columns: 1fr;

    gap: 32px;
  }
}

.references__item {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.references__image {
  display: block;
  width: 100%;
  height: 112px;
}

@media (--tablet) {
  .references__image {
    height: 160px;
  }
}

@media (--mobile) {
  .references__image {
    height: 112px;
  }
}

.references__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

* + .references__content {
  margin-top: 16px;
}

.references__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.references__content__bottom {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.references__item h3 {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--black);

  text-decoration: none;
}

.references__item a {
  text-decoration: none;
}

.references__item a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

@media (--tablet) {
  .references__item h3 {
    font-size: 20px;
  }
}

@media (--mobile) {
  .references__item h3 {
    font-size: 18px;
  }
}

.references__content .references__sub-title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(var(--black-rgb), 0.5);
}

@media (--tablet) {
  .references__content .references__sub-title {
    font-size: 12px;
  }
}

* + .references__cta {
  margin-top: 24px;
}

@media (--tablet) {
  * + .references__cta {
    margin-top: 16px;
  }
}

.references__cta a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.cultural-site {
  --color: var(--black);
  --color-rgb: var(--black-rgb);

  position: relative;

  margin-top: var(--section-gap);
}

.group--theme-black .cultural-site {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.cultural-site__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 88px;
  text-align: left;
}
@media (--tablet) {
  .cultural-site__wrap {
    gap: 56px;
  }
}
@media (--mobile) {
  .cultural-site__wrap {
    gap: 24px;
  }
}

.cultural-site__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (--tablet) {
  .cultural-site__container {
    flex-direction: column;
    gap: 24px;
  }
}

.cultural-site__sup-title-line {
  position: relative;

  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.1em;

  color: var(--color);
  text-transform: uppercase;
  white-space: nowrap;
}
@media (--mobile) {
  .cultural-site__sup-title-line {
    gap: 16px;
  }
}

.cultural-site__sup-title-line::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;

  background-color: var(--color);
}

.cultural-site__sup-title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}

.cultural-site__title-left {
  width: 136px;
}
@media (--tablet) {
  .cultural-site__title-left {
    width: 100%;
  }
}
@media (--mobile) {
  .cultural-site__title-left {
    display: none;
  }
}

.cultural-site__title-left p {
  max-width: 12ch;

  font-family: var(--font-family-text);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}
@media (--tablet) {
  .cultural-site__title-left p {
    max-width: 100%;
  }
}

.cultural-site__image {
  position: relative;

  overflow: hidden;
  width: 489px;
  height: auto;

  border-radius: 4px;
}
@media (--tablet) {
  .cultural-site__image {
    width: 100%;
    height: 307px;
  }
}

.cultural-site__image::before {
  position: absolute;

  top: 0;
  left: 0;

  content: '';

  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 80.38%,
    rgba(13, 13, 13, 0.9) 100%
  );
}

.cultural-site__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cultural-site__content {
  display: flex;
  flex-direction: column;
  width: 400px;
}
@media (--tablet) {
  .cultural-site__content {
    width: 100%;
  }
}

.cultural-site__title {
  font-family: var(--font-family-text);

  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: var(--color);
}
.cultural-site__title a {
  color: var(--color);
  text-decoration: none;
}
.cultural-site__title a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  content: '';
}
* + .cultural-site__title {
  margin-top: 12px;
}
@media (--mobile) {
  .cultural-site__title {
    font-size: 24px;
  }
  * + .cultural-site__title {
    margin-top: 32px;
  }
}

.cultural-site__description {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0.02em;
  color: var(--color);
}
* + .cultural-site__description {
  margin-top: 54px;
}
@media (--mobile) {
  * + .cultural-site__description {
    margin-top: 40px;
  }
}

* + .cultural-site__cta {
  margin-top: 72px;
}
@media (--mobile) {
  * + .cultural-site__cta {
    margin-top: 40px;
  }
}

* + .partners {
  margin-top: var(--section-gap);
}
.partners {
  position: relative;

  overflow: hidden;
}
.partners__body {
  display: flex;

  flex-wrap: wrap;
  align-items: center;

  justify-content: center;

  margin: auto;

  column-gap: 80px;
  row-gap: 40px;
}

.partners__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (--mobile) {
  .partners__item {
    width: 100%;
  }
}

.partners__item img {
  width: auto;
  height: 56px;
  object-fit: contain;
}

@media (--tablet) {
  .partners__logo__wrap img {
    height: 40px;
  }
}

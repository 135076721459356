.teams {
  --js-scroll-offset-top: var(--header-height);
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

* + .teams {
  margin-top: 60px;
}
.teams__wrap {
  display: flex;
  flex-direction: column;
}

.teams__title {
  position: relative;

  display: flex;
  align-items: center;
  padding-left: 16px;

  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.1em;
  color: var(--color);
}
.teams__title::before {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;

  content: '';
  display: block;

  border-right: 2px solid var(--color);
}

* + .teams__items {
  margin-top: 32px;
}

.teams__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  list-style: none;
}
@media (--tablet) {
  .teams__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .teams__items {
    grid-template-columns: 1fr;
  }
}

.teams__item {
  position: relative;

  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 402px;
  padding: 16px;

  color: var(--color);

  border-radius: 16px;
  background: linear-gradient(
      301deg,
      rgba(255, 255, 255, 0) 28.49%,
      rgba(255, 255, 255, 0.04) 72.4%
    ),
    #1e1e1e;
}
@media (--tablet) {
  .teams__item {
    min-height: 383px;
  }
}
@media (--mobile) {
  .teams__item {
    min-height: 100%;
  }
}

.teams__item__image {
  position: relative;

  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  border-radius: 8px;
}
@media (--tablet) {
  .teams__item__image {
    padding-bottom: 75%;
  }
}

.teams__item__image img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

* + .teams__item__top {
  margin-top: 26px;
}

.teams__item__top {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.teams__item__name {
  font-size: 18px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: 0.1em;
  color: var(--color);
}

.teams__item__fonction {
  position: relative;

  display: flex;
  align-items: center;

  padding-left: 10px;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.8);
}

.teams__item__fonction::before {
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 0;

  content: '';
  display: block;
  height: auto;

  border-right: 2px solid rgba(var(--color-rgb), 0.8);
}

.teams__item__button {
  display: flex;
  width: fit-content;
  margin-top: auto;
  padding-top: 12px;
}
.teams__item__button .button {
  position: static;
}

.teams__item__content {
  overflow-y: hidden;
}

.teams__item__content__inner {
  padding-top: 24px;
}

.teams__item__content__text {
  font-size: 15px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.8);
}

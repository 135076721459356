.image-text {
  --js-scroll-offset-top: var(--header-height);
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --padding-right: 0;
  --padding-left: 64px;
}
* + .image-text {
  margin-top: 64px;
}

.group--theme-black .image-text {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.image-texte--reverse {
  --padding-right: 64px;
  --padding-left: 0;
}

.image-text__wrapper {
  display: grid;
  grid-template-areas: 'image text';
  grid-template-columns: 1fr 1fr;
}

.image-texte--reverse .image-text__wrapper {
  grid-template-areas: 'text image';
}
@media (--mobile) {
  .image-text__wrapper {
    grid-template-areas: 'image' 'text';
    grid-template-columns: 1fr;
  }

  .image-texte--reverse .image-text__wrapper {
    grid-template-areas: 'image' 'text';
  }
}

.image-text__image {
  grid-area: image;
  width: 100%;
  max-width: 600px;
}

@media (--mobile) {
  .image-text__image {
    height: 310px;
  }

  .image-text__image img {
    height: 100%;
    object-fit: cover;
  }
}

.image-text__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: text;
  padding-right: var(--padding-right);
  padding-left: var(--padding-left);
}

.image-text__text__number {
  width: fit-content;

  font-family: var(--font-family-text);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.86;
  letter-spacing: 0.07em;
  color: rgba(var(--color-rgb), 0.5);
}

.image-text__text__number::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 8px;

  background-color: rgba(var(--color-rgb), 0.5);
}

.image-text__text__number + .image-text__text__title {
  margin-top: 32px;
}

.image-text__text__title {
  font-family: var(--font-family-text);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 0.75;
  letter-spacing: 0.05em;
  color: var(--color);
}

.image-text__text__title + .image-text__text__content {
  margin-top: 40px;
}

.image-text__text__content p {
  font-family: var(--font-family-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: 0.04em;
  color: var(--color);
}

.image-text__text__content h3 {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: rgba(var(--color-rgb), 0.6);
  text-transform: uppercase;
}

.image-text__text__content h3 + p {
  margin-top: 38px;
}

@media (--mobile) {
  .image-text__wrapper {
    grid-template-columns: 1fr;
  }
}

.image-text__image img {
  width: 100%;
  height: auto;
}

@media (--mobile) {
  .image-text__image img {
    height: 100%;
    object-fit: cover;
  }
}

@media (--mobile) {
  .image-text__text {
    padding-top: 32px;
    padding-left: 0;
  }
}
